<template>
  <div class="offline">
    <baskyt-progress />
    <div class="offline-text">It seems that you are offline.</div>
  </div>
</template>

<script>
export default {
  name: "Offline"
};
</script>

<style lang="scss" scoped>
.offline {
  display: grid;
  place-items: center;
  & ::v-deep .baskyt-progress__loader img {
    animation: rotate 1.5s infinite alternate;
    transform-origin: center;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(-50deg);
  }
}
</style>
